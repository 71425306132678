<template>
    <Toast/>
</template>

<script>
import Toast from 'primevue/toast';
const env_var = process.env.VUE_APP_SHOWSSONOTICE
export default {
    components: {
        Toast
    },
    mounted() {
        if (env_var === "true") {
            this.$toast.add({severity: 'info', summary: 'In the near future you will be required to use Login With Google', detail: '', life: 0});
        }
    }
}
</script>