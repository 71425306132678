import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Login from './components/Login.vue'; 

const loginApp = createApp(Login); 
loginApp.use(PrimeVue); 
loginApp.use(ToastService); 
loginApp.config.globalProperties.window = window; 

$(function() {
    if( $('#login').length) loginApp.mount('#login'); 
}); 